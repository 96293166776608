<template>
    <div class="bkColor">
        <headTitle :title="collectionDetails.projectName + title" :routerPath="routerPath"></headTitle>
        <div class="projectTitle">
            <div class="T">{{ collectionDetails.projectName }}的项目收款详情</div>
            <div class="D">{{ getReviewedStatus(collectionDetails.state) }}</div>
            <div style="font-size: 14px">流水号: {{ collectionDetails.gatheringNo }}</div>
        </div>
        <div class="projectBody">
            <van-field v-model="collectionDetails.projectName" label="项目信息" readonly rows="1" autosize type="textarea" />
            <van-field v-model="projectBelong" label="归属单位" readonly />
            <van-field v-model="guarantee" label="是否保证金" readonly />
            <van-field v-model="collectionDetails.userName" label="填写人" readonly />
            <van-field v-model="collectionDetails.price" label="收款金额" readonly />
            <van-field v-model="collectionDetails.description" label="收款说明" readonly autosize type="textarea" />
            <downloadFile :fileList="collectionDetails.fileList" :routerPath="`/collectionDetails?id=${id}`"></downloadFile>
        </div>
        <!-- <process-details :list="collectionDetails.reviewedList" :avatar="collectionDetails.avatar"
            :createTime="collectionDetails.createTime" :userName="collectionDetails.userName"></process-details> -->
    </div>
</template>

<script>
import headTitle from "../components/headTitle.vue";
import { Field, Uploader } from 'vant'
import Vue from 'vue';
Vue.use(Field)
Vue.use(Uploader)
import { timeChange, projectStatusText } from '../units/units'
import { getGathering } from "../api/gathering"
// import processDetails from "../components/processDetails.vue";
import downloadFile from "../components/downloadFile.vue";
export default {
    name: 'collectionDetails',
    components: {
        headTitle,
        // processDetails,
        downloadFile
    },
    mounted() {
        if (this.$route.query.routerPath) {
            this.routerPath = this.$route.query.routerPath
            console.log('..', this.routerPath)
        }
        this.id = this.$route.query.id
        this.getProgressDetail()
    },
    data() {
        return {
            title: '项目收款详情',
            id: '',
            collectionDetails: {
                projectName: '',//项目信息
                userName: '',//收款人
                price: '',//收款金额
                description: '',//收款说明
                fileList: [],//附件

            },
            guarantee: null,
            isReviewImg: false,//是否预览图片
            reViewImg: '',//预览的图片
            routerPath: '/pjCollection',
            projectBelong: ''
        }
    },
    methods: {
        getProgressDetail() {
            getGathering(this.id).then(res => {
                this.collectionDetails = res.data.data
                this.projectBelong = this.collectionDetails.belong == 1 ? '大美' : 
                               this.collectionDetails.belong == 2 ? '中创': 
                               this.collectionDetails.belong == 3 ? '广云仓' : 
                               this.collectionDetails.belong == 4 ? '大丰年' : ''
                // this.projectBelong = this.collectionDetails.belong == 1 ? '大美' : '中创'
                switch (this.collectionDetails.guarantee) {
                    case 1:
                        this.guarantee = '是'
                        break
                    case 2:
                        this.guarantee = '否'
                        break
                    default:
                        this.guarantee = '--'
                }
            })
        },
        reViewFile(url) {
            if (url.indexOf('.jpg') !== -1 || url.indexOf('.jpeg') !== -1 || url.indexOf('.png') !== -1) {
                this.isReviewImg = true
                this.reViewImg = url
                return
            }
            this.$router.push({
                path: "/preview",
                query: { url: url, routerPath: `/collectionDetails?id=${this.id}` },
            });
        },
        getTime(time) {
            return timeChange(time)
        },
        getReviewedStatus(s) {
            return projectStatusText(s)
        }

    }

}
</script>

<style lang="less" scoped>
.bkColor {
    width: 100vw;
    min-height: 100vh;
    background: #f6f6f6;
}

.projectTitle {
    width: 100vw;
    background: #fff;
    text-align: left;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 2vh;

    .T {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 1.5vh
    }

    .D {
        color: #F0A239;
        font-size: 1em;
    }
}

.projectBody {
    background: #fff;
    margin-bottom: 2vh;
}

/deep/ .van-cell {
    flex-wrap: wrap;
    margin-bottom: 1vh;
}

/deep/ .van-cell__title {
    color: #797979;
}

/deep/ .van-field__label,
.van-cell__title {
    width: 100vw;

}

/deep/ .van-cell::after {
    border-bottom: none;
}

.fileList {
    width: 100%;
    padding: 10px 16px;
    box-sizing: border-box;
    font-size: 14px;
    color: #797979;

    .title {
        text-align: left;
    }

    .fileImg {
        width: 80px;
        height: 80px;
    }

    .fileName {
        width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.reviewedList {
    padding: 10px 16px;
    background: #fff;

    .A {
        top: 30px !important;
    }

    .T {
        top: 35px !important
    }

    .U {
        top: 65px !important
    }

    .F {
        height: 110px !important;
    }
}

.title {
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
}

.reviewed {
    position: relative;
    height: 160px;
    padding: 10px 20px;
    box-sizing: border-box;
    overflow: hidden;

    .avatar {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 75px;
        left: 20px;
    }

    .status {
        position: absolute;
        top: 80px;
        left: 100px;
    }

    .time {
        position: absolute;
        top: 80px;
        right: 20px;
        color: #B4B4B4;
    }

    .userName {
        position: absolute;
        top: 110px;
        left: 100px;
        font-size: 1em;
        font-weight: 600;
    }
}
</style>